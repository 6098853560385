import React from "react";
import {
  Button,
  VStack,
  Text,
  useTheme,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import useRecipeConfigStore from "hooks/useRecipeConfigStore";

const CriteriaButtonGroup = () => {
  const theme = useTheme();
  const { criterias, criteriaList, toggleCriteria } = useRecipeConfigStore();

  const handleButtonClick = (criteria) => {
    toggleCriteria(criteria);
  };

  return (
    <VStack spacing={4} w="100%">
      <Text fontWeight="bold" fontSize="lg" color="text.dark">
        Recipe Priorities
      </Text>
      <Text fontSize="sm" color="gray.600">
        Optimize Your Plant-Based Creation
      </Text>
      <Wrap spacing={2} justify="center" w="100%">
        {criteriaList.map((criteria, index) => (
          <WrapItem key={index}>
            <Button
              bgColor={
                criterias[criteria]
                  ? theme.colors.coolGreen[500]
                  : theme.colors.bg.light
              }
              color={
                criterias[criteria]
                  ? theme.colors.bg.recipe
                  : theme.colors.text.dark
              }
              borderColor={theme.colors.coolGreen[500]}
              borderWidth={2}
              _hover={{
                bg: criterias[criteria]
                  ? theme.colors.coolGreen[500]
                  : theme.colors.bg.light,
                color: criterias[criteria]
                  ? theme.colors.bg.recipe
                  : theme.colors.text.dark,
              }}
              onClick={() => handleButtonClick(criteria)}
            >
              {criteria.charAt(0).toUpperCase() + criteria.slice(1)}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
    </VStack>
  );
};

export default CriteriaButtonGroup;
