import React, { memo } from "react";
import { Box, Image } from "@chakra-ui/react";

const Logo = ({ onLoad = () => {} }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mb={4}
    >
      <Image
        src="HappyRecipeLogo.png"
        alt="Happy Recipe Logo"
        style={{
          width: "100%",
          maxWidth: "400px",
          display: "block",
          margin: "auto",
        }}
        onLoad={onLoad}
      />
    </Box>
  );
};

export default memo(Logo);
