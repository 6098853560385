import { Box } from "@chakra-ui/react";
import React from "react";

const IngredientSelector = () => {
  return (
    <Box
      padding={6}
      fontSize={{ base: "md", md: "lg" }}
      fontWeight="500"
      color="text.dark"
      w="100%"
      h="50em"
      lineHeight="1.6"
      letterSpacing="0.02em"
      bgColor="bg.recipe"
      boxShadow="lg"
      borderRadius="xl"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      TODO
    </Box>
  );
};

export default IngredientSelector;
