import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import PlantBasedWishes from "./PlantBasedWishes";
import GenerateButton from "./GenerateButton";
import Logo from "./Logo";
import useRecipeConfigStore from "hooks/useRecipeConfigStore";
import { useNavigate } from "react-router-dom";
import CriteriaButtonGroup from "./CriteriaButtonGroup";

const RecipeGenerator = () => {
  const {
    criterias,
    additionalRemarks,
    setAdditionalRemarks,
    isIngredientSelectorEnabled,
    selectIngredients,
    generateRecipe,
  } = useRecipeConfigStore();
  const navigate = useNavigate();

  return (
    <>
      <Logo />
      <VStack spacing={4} alignItems="center" textAlign="center" w="100%">
        <Box h="1px" w="75%" bgColor="gray.200" />
        <PlantBasedWishes
          remarks={additionalRemarks}
          onChange={(e) => setAdditionalRemarks(e.target.value)}
        />
        <Box h="1px" w="75%" bgColor="gray.200" />
        <CriteriaButtonGroup />
        <Box h="1px" w="75%" bgColor="gray.200" />
        <GenerateButton
          onClick={() => {
            if (isIngredientSelectorEnabled) {
              selectIngredients(criterias, additionalRemarks);
              navigate("/select-ingredients");
              return;
            }
            generateRecipe();
            navigate("/recipe");
          }}
        />
      </VStack>
    </>
  );
};

export default RecipeGenerator;

/*
        <VStack spacing={2} w="100%">
          <Text textColor="text.dark" fontSize="lg" fontWeight="bold">
            Interactive mode
          </Text>
          <Text m={4} fontSize="sm" color="gray.600">
            Choose ingredients dynamically as you go through the recipe
          </Text>
        </VStack>
        <Box h="1px" w="75%" bgColor="gray.200" />
<Switch
            id="ingredient-interactive-selector"
            size="lg"
            colorScheme="coolGreen"
            isChecked={isIngredientSelectorEnabled}
            onChange={() =>
              setIsIngredientSelectorEnabled(!isIngredientSelectorEnabled)
            }
          />

          */
