import * as React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    coolGreen: {
      200: "#1261A0", // ChakraUI <Switch/> requires 200 🤷‍♂️.
      500: "#1261A0",
    },
    bg: {
      light: "#ddd9ce",
      recipe: "#faeedf",
    },
    text: {
      dark: "#444444",
      green: "#79943f",
    },
  },
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
});

const Chakra = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
);

export default Chakra;
