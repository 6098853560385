import { create } from "zustand";
import axios from "axios";
import { persist } from "zustand/middleware";

const criteriaList = [
  "convenient",
  "cheap",
  "creative",
  "delicious",
  "healthy",
];

const initialCriteria = Object.fromEntries(
  criteriaList.map((criteria) => [criteria, false])
);

const initialState = {
  criteriaList: criteriaList,
  criterias: initialCriteria,
  additionalRemarks: "",
  isIngredientSelectorEnabled: false,
  // TODO: move to different slice
  recipe: {},
  recipeError: null,
  generatingRecipe: false,
  ingredientTree: {
    error: null,
    warning: "",
    ingredients: [],
  },
};

const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://happyrecipebe.herokuapp.com";

const useRecipeConfigStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      toggleCriteria: (criteria) => {
        set({
          criterias: {
            ...get().criterias,
            [criteria]: !get().criterias[criteria],
          },
        });
      },
      setAdditionalRemarks: (additionalRemarks) => set({ additionalRemarks }),
      setIsIngredientEnabled: (isIngredientEnabled) =>
        set({ isIngredientEnabled }),
      selectIngredients: async (criterias, additionalRemarks) => {
        const body = {
          ...criterias,
          additionalInfo: additionalRemarks,
        };
        const resp = await axios.post(
          "http://localhost:8080/select-ingredients",
          body
        );
        if (resp.status === 200) {
          set({
            ingredientTree: {
              ...get().ingredientTree,
              warning: resp.data.warning,
              ingredients: resp.data.ingredients,
            },
          });
        } else {
          set({ ingredientTree: { error: resp.data } });
        }
      },
      reset: () => set(initialState),
      generateRecipe: async () => {
        if (get().generatingRecipe) return;

        set({ recipeError: null, generatingRecipe: true, recipe: {} });

        const criteriaQueryParam = `criterias=${JSON.stringify(
          get().criterias
        )}`;
        const additionalRemarksQueryParam = `additionalRemarks=${encodeURIComponent(
          get().additionalRemarks
        )}`;
        const source = new EventSource(
          `${API_URL}/generate-recipe?${[
            criteriaQueryParam,
            additionalRemarksQueryParam,
          ]
            .filter((q) => q)
            .join("&")}`
        );

        source.onmessage = (event) => {
          if (!event.data) {
            return;
          }

          const newRecipeData = JSON.parse(event.data);
          // Check for the end-of-stream message
          if (newRecipeData.endOfStream) {
            source.close();
            set({ generatingRecipe: false });
            return;
          }

          let updatedRecipe = { ...get().recipe };
          Object.entries(newRecipeData)
            .filter(([_, value]) => value)
            .forEach(([key, value]) => {
              if (!updatedRecipe[key]) {
                updatedRecipe[key] = "";
              }
              updatedRecipe = {
                ...updatedRecipe,
                [key]: updatedRecipe[key] + newRecipeData[key],
              };
            });

          set({ recipe: updatedRecipe });
        };

        source.onerror = (error) => {
          source.close();
          set({ generatingRecipe: false, recipeError: error });
        };
      },
    }),
    {
      name: "happy-recipe-config-store",
      version: 2,
    }
  )
);

export default useRecipeConfigStore;
