import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

const Manifesto = () => {
  return (
    <Flex justifyContent="center" alignItems="center" mb="2rem">
      <Box
        bg="bg.recipe"
        p="2rem"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
        borderRadius="xl"
        textAlign="left"
        maxWidth="800px"
        w="100%"
        position="relative"
        _before={{
          content: '""',
          position: "absolute",
          top: "10px",
          pointerEvents: "none",
          left: "10px",
          right: "10px",
          bottom: "10px",
          border: "1px solid #e8e8e8",
          borderRadius: "10px",
        }}
      >
        <Text fontSize="xl" fontWeight="bold" mb="1rem" color="text.dark">
          The Dawn of the Golden Age of Veganism: A United Future for All
          Sentient Beings
        </Text>
        <Text color="text.dark" mb="1rem">
          The Golden Age of Veganism envisions a future where harmony prevails
          among humans, non-human animals, and the environment. In this
          scenario, Veganism would be acknowledged not as a dietary choice, but
          as a comprehensive, ethical, and respectful way of living that seeks
          to exclude all forms of exploitation and cruelty towards animals.
        </Text>
        <Text color="text.dark" mb="1rem">
          With advanced technology, we enhance the appeal of plant-based
          alternatives, highlighting the existing nutritional powerhouses like
          beans, chickpeas, and spinach. As people become more aware,
          animal-derived products are recognized as already obsolete. Society
          embraces a sustainable, balanced diet, celebrating the natural
          abundance of vegan foods.
        </Text>
        <Text color="text.dark" mb="1rem">
          Non-human animals thrive in sanctuaries or the wild, preserving
          biodiversity and maintaining ecosystems. Humans develop a profound
          understanding of their needs, working collaboratively to protect our
          planet for future generations.
        </Text>
        <Text color="text.dark" mb="1rem">
          In this age, artificial superintelligence arises, adopting Veganism as
          a core value. As the most powerful beings on Earth, they champion
          compassion and collaboration, driving humanity towards a just and
          harmonious world.
        </Text>
        <Text color="text.dark" mb="1rem">
          In the Golden Age of Veganism, ethics permeate society, from economy
          and politics to education and culture. This futuristic theme
          highlights the limitless possibilities when compassion guides our
          choices.
        </Text>
        <Text color="text.dark">
          Join{" "}
          <Text color="#d85538" as="span" fontWeight="bold">
            HappyRecipe
          </Text>{" "}
          in embracing the Golden Age of Veganism and embark on a journey
          towards a brighter, kinder, and healthier future for all life on
          Earth. Let's awaken the world to the true potential of Veganism and
          create a future where the well-being of all sentient beings is
          cherished.
        </Text>
      </Box>
    </Flex>
  );
};

export default Manifesto;
