import React from "react";
import { Box, Container, Text } from "@chakra-ui/react";
import RecipeGenerator from "./components/RecipeGenerator";
import RecipeDisplay from "./components/RecipeDisplay";
import NavigationBar from "./components/NavigationBar";
import Manifesto from "./components/Manifesto";
import { Route, Routes, useNavigate } from "react-router-dom";
import IngredientSelector from "components/IngredientSelector";

function App() {
  const navigate = useNavigate();

  const handleGenerateAnotherRecipe = () => {
    navigate("/");
  };

  return (
    <Box bg="bg.light" minHeight="100vh">
      <NavigationBar />
      <Container maxW="container.xl" py={8}>
        <Routes>
          <Route path="/about-us" element={<Manifesto />} />
          <Route path="/" element={<RecipeGenerator />} />
          <Route
            path="/recipe"
            element={
              <RecipeDisplay
                onGenerateAnotherRecipe={handleGenerateAnotherRecipe}
              />
            }
          />
          <Route path="/select-ingredients" element={<IngredientSelector />} />
          <Route path="*" element={<Text color="text.dark">Not found</Text>} />
        </Routes>
      </Container>
    </Box>
  );
}

export default App;
