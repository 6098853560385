import React from "react";
import { Box, Flex, Link, Spacer, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const NavigationBar = () => {
  return (
    <Box bg="bg.recipe" boxShadow="lg" py={2}>
      <Flex maxW="container.xl" mx="auto" px={4} alignItems="center">
        <Text fontSize="xl" fontWeight="bold" color="#d85538">
          <RouterLink to="/">HappyRecipe</RouterLink>
        </Text>
        <Spacer />
        <Flex>
          <Link as={RouterLink} to="/about-us" mx={4}>
            <Text fontSize="md" fontWeight="bold" color="text.dark">
              About Us
            </Text>
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavigationBar;
