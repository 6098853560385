import React from "react";
import { VStack, Text, Textarea } from "@chakra-ui/react";

const PlantBasedWishes = ({ remarks, onChange }) => {
  return (
    <VStack spacing={4} w="100%">
      <Text fontWeight="bold" fontSize="lg" color="text.dark">
        Plant-Powered Wishes
      </Text>
      <Text fontSize="sm" color="gray.600">
        Add any specific preferences, dietary restrictions, or ingredient
        requests.
      </Text>
      <Textarea
        bgColor="bg.recipe"
        placeholder="Type your additional remarks here..."
        value={remarks}
        onChange={onChange}
        size="md"
        borderColor="coolGreen.500"
        _hover={{
          borderColor: "coolGreen.500",
        }}
        _focus={{
          borderColor: "coolGreen.500",
          boxShadow: "0 0 0 1px coolGreen.500",
        }}
        maxWidth="75%"
        height="160px"
        textColor="text.dark"
        fontWeight={600}
      />
    </VStack>
  );
};

export default PlantBasedWishes;
