import React from "react";
import { Button, Spinner, Text } from "@chakra-ui/react";

const GenerateButton = ({ onClick }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return isLoading ? (
    <Spinner
      size="lg"
      color="coolGreen.500"
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      ml={2}
    />
  ) : (
    <Button
      size="lg"
      bgColor="text.green"
      onClick={() => {
        setIsLoading(true);
        onClick();
      }}
      boxShadow="md"
      _hover={{
        boxShadow: "lg",
      }}
      _active={{
        boxShadow: "xl",
      }}
    >
      <Text color="bg.recipe">Generate Recipe</Text>
    </Button>
  );
};

export default GenerateButton;
